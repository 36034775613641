import React from "react";
import {
  StyledWrapper,
  StyledTextBig,
  StyledTextSmall,
} from "./style";

export default ({ title, subtitle }) => {
  return (
    <StyledWrapper>
      <StyledTextBig>
        {title}
      </StyledTextBig>
      <StyledTextSmall>
        {subtitle}
      </StyledTextSmall>
    </StyledWrapper>
  );
};
